import { useState, useCallback } from 'react';
import { fixBodyScroll, unfixBodyScroll } from 'lib/browser';

export const useModal = () => {
  const [isOpen, setOpen] = useState(false);

  const openModal = useCallback(() => {
    setOpen(true);
    fixBodyScroll();
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
    unfixBodyScroll();
  }, []);

  return {
    openModal,
    closeModal,
    modalProps: { isOpen, shouldCloseOnOverlayClick: true, onRequestClose: closeModal },
  };
};
